@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Public Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

:root {
  --base-background-color: rgb(250, 250, 250, 1);
}

body,
html {
  width: 100%;
  height: 100%;
  font-size: 16px;
  background-color: var(--base-background-color);
}

@media (prefers-color-scheme: dark) {
  :root {
    --base-background-color: rgb(41, 41, 41, 1);
    color: white;
  }
}
